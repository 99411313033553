export enum ETypeToom {
  Indoor = 1,
  Outdoor = 2,
  Greenhouse = 3
}
export enum ETypeLamp {
  Fl = 1,
  Hid = 2,
  Led = 3
}

export enum ECategoryType {
  AirConditioner = 'air_conditioner',
  AirFilter = 'air_filter',
  CO2Generator = 'co2_generator',
  Controller = 'controller',
  DripSystem = 'drip_system',
  GrowBox = 'grow_box',
  HydroponicSystem = 'hydroponic_system',
  Lamp = 'lamp',
  Medium = 'medium',
  Nutrient = 'nutrient',
  Seed = 'seed',
  Tent = 'tent',
  VentilationFan = 'ventilation_fan',
}

export const RCategoryDescriptions: Record<ECategoryType, string> = {
  [ECategoryType.AirConditioner]: 'Air Conditioner – Devices that regulate temperature and humidity, maintaining optimal climate conditions for plant growth in controlled environments.',
  [ECategoryType.AirFilter]: 'Air Filter – Systems that purify the air by removing contaminants, ensuring a clean and healthy environment for plants and cultivation spaces.',
  [ECategoryType.CO2Generator]: 'CO2 Generator – Equipment that generates carbon dioxide to enhance photosynthesis, promoting healthier and faster plant growth in enclosed spaces.',
  [ECategoryType.Controller]: 'Controller – Centralized systems that manage and automate various aspects of the growing environment, including temperature, humidity, lighting, and ventilation.',
  [ECategoryType.DripSystem]: 'Drip System – Irrigation systems that deliver water and nutrients directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
  [ECategoryType.GrowBox]: 'Grow Box – Compact, all-in-one systems for indoor cultivation, featuring integrated lighting, ventilation, and often hydroponic setups to provide a self-contained environment for plant growth.',
  [ECategoryType.HydroponicSystem]: 'Hydroponic System – Soilless cultivation systems that deliver nutrients directly to plant roots through a water-based solution, enabling efficient and scalable plant growth.',
  [ECategoryType.Lamp]: 'Lamp – High-performance lighting solutions designed to provide the necessary light spectrum and intensity for photosynthesis, essential for indoor plant growth.',
  [ECategoryType.Medium]: 'Medium – Substrates used to support plant roots, providing structure and retaining moisture and nutrients in soilless or soil-based cultivation systems.',
  [ECategoryType.Nutrient]: 'Nutrient – Specialized formulations that supply essential minerals and compounds necessary for plant growth, ensuring healthy and vigorous development.',
  [ECategoryType.Seed]: 'Strains – High-quality plant seeds, the foundation of cultivation, chosen for their genetics and suitability to the growing environment.',
  [ECategoryType.Tent]: 'Tent – Portable, reflective enclosures that create a controlled growing environment by containing light, temperature, and humidity for optimal plant cultivation.',
  [ECategoryType.VentilationFan]: 'Ventilation Fan – Fans and ventilation systems that ensure proper air circulation, removing excess heat and humidity while supplying fresh air to maintain a healthy growing environment.',
};


export const RCategoryImages: Record<ECategoryType, string> = {
  [ECategoryType.AirConditioner]: '/images/product-categories/air_conditioner.svg',
  [ECategoryType.AirFilter]: '/images/product-categories/air_filter.svg',
  [ECategoryType.CO2Generator]: '/images/product-categories/co2_generator.svg',
  [ECategoryType.Controller]: '/images/product-categories/controller.svg',
  [ECategoryType.DripSystem]: '/images/product-categories/drip_system.svg',
  [ECategoryType.GrowBox]: '/images/product-categories/grow_box.svg',
  [ECategoryType.HydroponicSystem]: '/images/product-categories/hydroponic_system.svg',
  [ECategoryType.Lamp]: '/images/product-categories/lamp.svg',
  [ECategoryType.Medium]: '/images/product-categories/medium.svg',
  [ECategoryType.Nutrient]: '/images/product-categories/nutrient.svg',
  [ECategoryType.Seed]: '/images/product-categories/seed.svg',
  [ECategoryType.Tent]: '/images/product-categories/tent.svg',
  [ECategoryType.VentilationFan]: '/images/product-categories/ventilation_fan.svg',
};

export const RTypeLampImages: Record<ETypeToom, string> = {
  [ETypeLamp.Fl]: '/images/product-categories/lamp_1.svg',
  [ETypeLamp.Hid]: '/images/product-categories/lamp_2.svg',
  [ETypeLamp.Led]: '/images/product-categories/lamp_3.svg'
};

export const RTypeRoomImages: Record<ETypeToom, string> = {
  [ETypeToom.Indoor]: '/images/setup_diary/setup_diary_icons-01.svg',
  [ETypeToom.Outdoor]: '/images/setup_diary/setup_diary_icons-02.svg',
  [ETypeToom.Greenhouse]: '/images/setup_diary/setup_diary_icons-19.svg'
};

export const RTypeRoomDescriptions: Record<ETypeToom, string> = {
  [ETypeToom.Indoor]: '/images/setup_diary/setup_diary_icons-01.svg',
  [ETypeToom.Outdoor]: '/images/setup_diary/setup_diary_icons-02.svg',
  [ETypeToom.Greenhouse]: '/images/setup_diary/setup_diary_icons-19.svg'
};


export enum ELampFaza {
  veg = 0,
  flo = 1
}

export const ECategorySort = [
  ECategoryType.Seed,
  ECategoryType.Nutrient,
  ECategoryType.Lamp,
  ECategoryType.Tent,
  ECategoryType.GrowBox,
  ECategoryType.VentilationFan,
  ECategoryType.AirFilter,
  ECategoryType.AirConditioner,
  ECategoryType.Controller,
  ECategoryType.DripSystem,
  ECategoryType.HydroponicSystem,
  ECategoryType.CO2Generator
]


export const TabsDiary = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'nutrients',
    name: 'Nutrients',
  }, 
  {
    id: 'comments',
    name: 'Comments',
  },
]

export const TabsDiaryHar = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'reviews',
    name: 'Reviews',
  },  
  {
    id: 'comments',
    name: 'Comments',
  },
]